(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/game-templates/assets/javascripts/services/fetch-single-template.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/game-templates/assets/javascripts/services/fetch-single-template.js');
"use strict";


const {
  helpers
} = svs.components.marketplaceRetailAdminData.gameTemplates;
const {
  jupiter
} = svs.core;
function fetchSingleTemplate(groupId, templateId, callback) {
  jupiter.get("/retailer/1/marketplace/groups/containers/".concat(templateId), response => {
    const template = helpers.mapGameTemplate(response.container);
    callback(null, {
      template,
      user: {
        editorId: template.ownerId
      }
    });
  }, error => {
    callback(error.responseJSON.error);
  });
}
setGlobal('svs.components.marketplaceRetailAdminData.gameTemplates.services.fetchSingleTemplate', fetchSingleTemplate);

 })(window);