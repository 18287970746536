(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/game-templates/assets/javascripts/helpers/map-game-template.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/game-templates/assets/javascripts/helpers/map-game-template.js');
'use strict';

const {
  getProdName
} = svs.components.lbUtils.sportProducts;
const mapGameTemplate = container => ({
  canCreateGame: true,
  buyStopDiff: container.defaultBuyinCloseMinBeforeRegclose,
  description: container.description,
  id: container.containerId,
  isActive: container.activatedDrawExists || !container.canBeDeleted,
  maxFractions: container.numShares,
  name: container.name,
  ownerId: container.editorId,
  productId: container.productId,
  productName: getProdName(container.productId),
  sortOrder: container.sortOrder,
  fractionPrice: Number(container.sharePrice.replace(',', '.')),
  editorName: container.editorName,
  maxPlayableAmount: Number(container.maxPlayableAmount.replace(',', '.')),
  commissionPercent: container.commissionPercent,
  activatedDraws: container.activatedDraws,
  isAutoOpened: container.isAutoOpened
});
setGlobal('svs.components.marketplaceRetailAdminData.gameTemplates.helpers.mapGameTemplate', mapGameTemplate);

 })(window);