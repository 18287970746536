(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/game-templates/assets/javascripts/services/update-template.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/game-templates/assets/javascripts/services/update-template.js');
"use strict";


const {
  jupiter
} = svs.core;
const updateTemplateDescription = (containerId, gameTemplate) => callback => {
  jupiter.put({
    path: "/retailer/1/marketplace/containers/".concat(containerId, "/description"),
    data: {
      description: gameTemplate.description
    }
  }, () => callback(null, gameTemplate), err => callback(err.responseJSON.error));
};
const updateTemplateEverythingButDescription = (containerId, gameTemplate) => callback => {
  jupiter.put({
    path: "/retailer/1/marketplace/container/".concat(containerId),
    data: {
      editorId: gameTemplate.ownerId,
      defaultBuyinCloseMinBeforeRegclose: gameTemplate.buyStopDiff,
      isAutoOpened: gameTemplate.isAutoOpened
    }
  }, () => callback(null, gameTemplate), error => callback(error.responseJSON.error));
};
const updateTemplate = (containerId, template, callback) => {
  if (!containerId) {
    return callback(new Error('Någonting gick fel. Ladda om sidan och försök igen.'));
  }
  async.parallel([updateTemplateEverythingButDescription(containerId, template), updateTemplateDescription(containerId, template)], (err, result) => {
    if (err) {
      return callback(err);
    }
    return callback(null, ...result);
  });
};
setGlobal('svs.components.marketplaceRetailAdminData.gameTemplates.services.updateTemplate', updateTemplate);

 })(window);