(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/game-templates/assets/javascripts/services/fetch-templates.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/game-templates/assets/javascripts/services/fetch-templates.js');
"use strict";


const {
  helpers
} = svs.components.marketplaceRetailAdminData.gameTemplates;
const {
  jupiter
} = svs.core;
const fetchTemplates = callback => {
  const apiVersion = 2;
  jupiter.get("/retailer/".concat(apiVersion, "/marketplace/groups/containers"), response => {
    callback(null, {
      templates: (response.containers || []).map(helpers.mapGameTemplate),
      users: []
    });
  }, error => {
    callback(error.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceRetailAdminData.gameTemplates.services.fetchTemplates', fetchTemplates);

 })(window);